<template>
    <div>
        <div class="das_top_cont">
            <div class="history_box">
                <div class="title flex_center_between_box">
                    <div class="title_txt flex_center_start_box">
                        <img src="../../assets/img/account/buling.png" />
                        Billing
                    </div>
                    <div class="c_d_buttom flex_box">
                        <button class="btn_blue btn_blu flex_center" @click="ResetHistory">
                            <img src="../../assets/img/account/update.png" />
                            Reset
                        </button>
                        <button class="btn_blue flex_center" @click="searchHistory">
                            <img src="../../assets/img/detail/btn_ic5.png" />
                            Search
                        </button>
                    </div>
                </div>
                <div class="right_cont_box">
                    <div class="his_sel_box flex__box">
                        <div class="sel_box">
                            <!-- <el-autocomplete class="inline-input" v-model="chargeType" :fetch-suggestions="querySearch" placeholder="Order No."></el-autocomplete> -->
                            <el-select v-model="CategoryVal" placeholder="Charge Type">
                                <el-option v-for="item in   IndicatorsOptions" :key="item" :label="item"
                                    :value="item"></el-option>
                            </el-select>
                        </div>
                        <div class="sel_box">
                            <!-- <el-autocomplete class="inline-input" v-model="invoiceNumber" :fetch-suggestions="querySearchtwo" placeholder="Invoice No."></el-autocomplete> -->
                            <!-- <el-select v-model="invoiceNumber" placeholder="Invoice No.">
                                <el-option v-for="item in   IndicatorsOptions" :key="item.invoiceNumber" :label="item.invoiceNumber"
                                    :value="item.invoiceNumber"></el-option>
                            </el-select> -->
                        </div>

                        <div class="sel_box">
                            <el-date-picker v-model="value1" type="daterange" range-separator="" value-format="yyyy-MM-dd" start-placeholder="Start Date" end-placeholder="End Date"></el-date-picker>
                        </div>
                    </div>

                    <div class="top_tab">
                        <div class="table_box">
                            <el-table ref="UsageList" :data="UsageList" border style="width: 100%">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">No Data</template>
                                <el-table-column label="Order date" prop="date" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Order no." align="center" prop="orderCode" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Amount $" width="120" prop="amount" align="right" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Charge type" prop="chargeType" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Payment method" prop="payMethod" align="center" show-overflow-tooltip :resizable="false" />
                                <!-- <el-table-column label="Invoice No." align="center" prop="invoiceNumber" show-overflow-tooltip :resizable="false" /> -->
                              
                                <el-table-column label="Billing details" width="100" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <div class="flex_center">
                                            <el-tooltip class="tbl_opr_tip" :visible-arrow="false" effect="light" content="download" placement="top">
                                                <div style="width: 80px;" class="oper_down" @click="hanldeDown(scope.row)">
                                                    <img src="../../assets/img/account/download.png" />
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <div class="page_i_box">
                                <Page :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum}} results</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- List of login duration and times 弹框 -->
        <el-dialog :visible.sync="timesDetailDialog" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont">
                <div class="popTitle">{{ detailObj.titleDate }}</div>
                <div class="detail_pop_list">
                    <div class="detail_pop_title_box flex_center">
                        <div class="detail_pop_title">Date</div>
                        <div class="detail_pop_title">Login duration</div>
                        <div class="detail_pop_title">Number of logins</div>
                    </div>
                    <div class="detail_pop_item flex_center" v-for="(item, index) in detailObj.results" :key="index">
                        <div class="detail_pop_txt">{{ item.Date }}</div>
                        <div class="detail_pop_txt">{{ item.LoginDuration }}</div>
                        <div class="detail_pop_txt">{{ item.NumberOfLogins }}</div>
                    </div>
                </div>
                <div class="txt_right">
                    <button class="btn_export">Yes</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Page from '@/components/page';
import { getPdf, getOrderInvoiceList } from '../../api/userApi';
import { mixins1 } from '@/mixins/index'
export default {
    components: { Page },
    mixins: [mixins1],
    data() {
        return {
            value1: '',
            CategoryVal: '',

            detailObj: {
                titleDate: '2022-11',
                results: [
                    { Date: '2022/11/14', LoginDuration: '10h', NumberOfLogins: '1' },
                    { Date: '2022/11/14', LoginDuration: '12h', NumberOfLogins: '1' },
                    { Date: '2022/11/14', LoginDuration: '10h', NumberOfLogins: '1' },
                ],
            },
            IndicatorsOptions: ['Starter','Standard','Subscriber','Enterprise'],
            invoiceNumber: '',
            timesDetailDialog: false, //List of login duration and times 弹框
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            chargeType:'',
            UsageList: [{ Order: '20221114001', Invoice: '123456789', Date: '2022-11-14', Amount: 'asc22', operation: 'operation' }],
        };
    },
    mounted() {
        this.getportfoillist();
    },
    methods: {
       
        async getportfoillist() {
          
            const params = {
                page: this.page.pageNo,
                limit: this.page.pageSize,
                invoiceNumber: this.invoiceNumber,
                // orderCode: this.CategoryVal,
                startTime: this.value1[0]==undefined?'':this.value1[0],
                endTime: this.value1[1]==undefined?'':this.value1[1],
                chargeType:this.CategoryVal
            };
            let res = await getOrderInvoiceList(params);
            if (res.code) {
                this.UsageList = res.data.rows;
                this.page.total = res.data.total;
                // this.IndicatorsOptions = res.data.rows;
               
            } else {
                this.UsageList = [];
            }
        },
        //分页
        onPageChangeList() {},
        onChildPageChange() {},

        //tab切换
        handleTabClick() {},
        //Order List
        async hanldeDown(row) {
            const orderId = row.orderId;
            await getPdf(orderId).then(res => {
                if (res && res.code) {
                
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: 'success',
                    });
                    window.open(res.data, '_blank');
                } else {
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: 'error',
                    });
                }
            });
        },
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.getportfoillist();
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.getportfoillist();
        },
        //Recharge list
        hanldeApply(row) {},
        //List of login duration and times
        hanldeDetails(row) {
            this.timesDetailDialog = true;
        },
        ResetHistory() {
            this.CategoryVal = '';
            this.value1 = '';
            this.invoiceNumber = '';
        },
        searchHistory() {
            this.getportfoillist();
        },
    },
};
</script>
<style scoped>
/* 日历大小 */
.el-range-editor.el-input__inner {
    width: 224px !important;
    padding: 3px 3px;
}

.el-date-editor /deep/ .el-range__icon {
    font-size: 14px;
    margin-left: -5px;
    color: #c0c4cc;
    /* float: left; */
    line-height: 32px;
    position: relative;
    left: 47%;
}

.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.country_box {
    margin-top: 60px;
}

.country_box .title {
    margin-bottom: 0;
}

.history_box {
    /* margin-top: 22px; */
}

.title {
    margin-bottom: 20px;
}

.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}

.title_txt img {
    width: 20px;
    margin-right: 8px;
}

.c_d_buttom {
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 0;
}

.btn_blue {
    width: 140px;
    height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}

.btn_blu {
    margin-right: 20px;
}

.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}

.his_sel_box .sel_box /deep/ .el-input__inner {
    border-color: #8497ab;
    border-radius: 8px;
}

.his_sel_box .sel_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab;
    font-weight: 500;
}

.das_top_cont {
    /* margin-top: 50px; */
}

.das_top_item {
    position: relative;
    width: 230px;
    height: 232px;
    padding: 20px 20px 10px;
    border-radius: 14px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}

.das_top_item .item_img {
    position: absolute;
    left: 95px;
    top: -20px;
    width: 40px;
    height: 40px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
}

.das_top_item .color1 {
    background: #1290c9;
}

.das_top_item .color2 {
    background: #f56b6b;
}

.das_top_item .color3 {
    background: #55b419;
}

.das_top_item .color4 {
    background: #ff7600;
}

.das_top_item .item_img img {
    width: 18px;
    height: 18px;
}

.das_top_item .item_title {
    font-size: 18px;
    color: #022955;
    margin: 10px 0 10px;
    text-align: center;
}

.das_top_item .item_group {
    margin-bottom: 10px;
    font-size: 14px;
}

.das_top_item .item_lab {
    display: block;
    color: #8497ab;
    margin-bottom: 8px;
}

.top_tab {
    margin-top: 40px;
}

.result_tab {
    padding: 20px;
}

.flex__box {
    display: flex;
    align-items: center;
}

.oper_down img {
    width: 18px;
    height: 16px;
}

.detail_pop_list {
    margin: 40px auto 0;
}

.detail_pop_title_box {
    border-bottom: 1px solid #e7eaed;
    padding-bottom: 10px;
}

.detail_pop_item {
    border-bottom: 1px solid #e7eaed;
    padding: 15px 0;
}

.detail_pop_txt,
.detail_pop_title {
    width: 33.3%;
    text-align: center;
    font-size: 16px;
}

.detail_pop_title {
    color: #8497ab;
}

.detail_pop_txt {
    color: #022955;
}

.txt_right {
    text-align: right;
    margin-top: 15px;
}

.sel_box {
    margin-right: 15px;
}
</style>